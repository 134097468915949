import {format, distanceInWords, differenceInDays, parseISO} from 'date-fns'
import React from 'react'
import {Link} from 'gatsby'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'
import RoleList from './role-list'
import * as styles from './project.module.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Header from '../components/header'
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    textAlign: 'right'
  },
  alert: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function Product (props) {
  const classes = useStyles();
  const {_rawBody, title, category, photo, material, publishedAt} = props
  return (
    <>
      <article className={styles.root}>
        {props.photo && photo.asset && (
          <div style={{overflow: 'hidden', position: 'relative', width: '100%', height: '177.78%', marginTop: 100}}>
            <img
              src={imageUrlFor(buildImageObj(photo)).height(Math.floor((9/16)*1300)).fit('max').url()}
            />
          </div>
        )}
        
        <Container>
          <div className={styles.grid}>
            <div className={styles.mainContent}>
              <h1 className={styles.title}>{title}</h1>
              {/* {publishedAt && (
                <div className={styles.publishedAt}>
                  {differenceInDays(parseISO(publishedAt), new Date()) > 3
                    ? distanceInWords(parseISO(publishedAt), new Date())
                    : format(parseISO(publishedAt), 'dd/MM/yyyy')}
                </div>
              )} */}
              <div style={{textAlign: 'justify'}}>{_rawBody && <BlockContent blocks={_rawBody || []} />}</div>
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Alert className={classes.alert} severity="warning" icon={false}>
                    {category && (
                      <h3 className={styles.categoriesHeadline} style={{margin: 'auto'}}><span style={{color: "#263238"}}>Κατηγορία: </span> {category.title}</h3>
                    )}
                  </Alert>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Alert className={classes.alert} severity="warning" icon={false}>
                    {material && (
                      <h3 className={styles.categoriesHeadline} style={{margin: 'auto'}}><span style={{color: "#263238"}}>Υλικό: </span> {material.title}</h3>
                    )}
                  </Alert>
                </Grid>
              </Grid>
              <Button variant="outlined" color="primary" style={{width: '100%'}} href="/">επιστροφη στην αρχικη σελιδα</Button>
            </div>
            
          </div>
        </Container>
      </article>
    </>
  )
}

export default Product
