import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Product from '../components/project'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      address
      phone
      mobile
      email
      keywords
      logo {
        asset {
          url
          _id
        }
      }
    },
    product: sanityProduct(id: {eq: $id}) {
      id
      publishedAt
      category {
        _id
        title
      }
      material {
        _id
        title
      }
      photo {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      title
      slug {
        current
      }
      _rawBody
    }
  }
`

const ProjectTemplate = props => {
  const {data, errors} = props
  const product = data && data.product
  const site = (data || {}).site

  return (
    <Layout logo={site.logo.asset.url} address={site.address} telephone={site.phone} mobile={site.mobile} email={site.email}>
      {errors && <SEO title='GraphQL Error' />}
      {product && <SEO title={product.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {product && <Product {...product} />}
    </Layout>
  )
}

export default ProjectTemplate
